import React from "react";
import "./App.css";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import { useRouteMatch } from "react-router";

const HomePage = React.lazy(() => import("./pages/index"));
const AboutUs = React.lazy(() => import("./pages/About-us"));
const Report = React.lazy(() => import("./pages/Report"));
const ShowPdf = React.lazy(() => import("./pages/Report/showpdf"));
const Program = React.lazy(() => import("./pages/Program"));
const Donation = React.lazy(() => import("./pages/Donation"));
const Join = React.lazy(() => import("./pages/Join"));

const App: React.FC = () => {
  const match = useRouteMatch();

  return (
    <div className="App">
      <GuardProvider guards={[]}>
        <React.Suspense fallback={<div></div>}>
          <GuardedRoute path={`${match.url}`} exact component={HomePage}></GuardedRoute>
          <GuardedRoute path={`${match.url}about-us`} exact component={AboutUs}></GuardedRoute>
          <GuardedRoute path={`${match.url}report`} exact component={Report}></GuardedRoute>
          <GuardedRoute path={`${match.url}show-reports`} exact component={ShowPdf}></GuardedRoute>
          <GuardedRoute path={`${match.url}program`} exact component={Program}></GuardedRoute>
          <GuardedRoute path={`${match.url}donation`} exact component={Donation}></GuardedRoute>
          <GuardedRoute path={`${match.url}join`} exact component={Join}></GuardedRoute>
        </React.Suspense>
      </GuardProvider>
    </div>
  );
};

export default App;
